import { useState, useContext, useEffect } from "react";
import {
  JustifyContent,
  ColorPreset,
  AlignItems,
  CSSRulesFunction,
  useTheme,
  Grid,
  Visibility,
  Interpose,
  Space,
  Container,
  Column,
  Box,
  ColorProp,
  BoxProps,
  Separator,
  ButtonLayout,
  PlainLink,
  Breakpoint,
  PlainButton,
  FontWeight,
  Text,
  TextWrap,
} from "@gocardless/flux-react";
import Image from "next/image";

import GoCardlessLogo from "../../../GoCardlessLogo";
import { MobilePrimaryNavigation } from "../../../navigation";
import {
  SiteSettingsDropdown,
  SiteSettingsDialog,
} from "../../../site-settings";
import { LinkBuilder, Route } from "../../../routing";
import { GlobalState } from "../../../global-state/GlobalStateProvider";
import { SearchFormContainer } from "../../../search";
import { useIsEligibleForReferral } from "../../../routes/Refer/hooks/useIsEligibleForReferral";
import { OrganisationSwitcher } from "../../../routes/Accounts/OrganisationSwitcher/OrganisationSwitcher";
import {
  NotificationsFullScreenDialog,
  NotificationsSideBar,
} from "../../../notifications";
import ReferButton from "../../ReferButton";
import ChooseReferralRewardReferButton from "../../ChooseReferralRewardReferButton";
import { SingleFullLayoutWithNav } from "../SingleFullLayoutWithNav";
import { SingleFixedLayoutWithNav } from "../SingleFixedLayoutWithNav";

import { Sidebar } from "./Sidebar";

import NotificationBell from "src/assets/svg/notification-bell.svg";
import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import ExtendDiscountDialog from "src/components/incentive-banner/ExtendDiscountDialog";
import ExtendDiscountBanner from "src/components/incentive-banner/ExtendDiscountBanner";
import ExtendDiscountButton from "src/components/incentive-banner/ExtendDiscountButton";
import { useMultiAccountFeature } from "src/libraries/organisation/multi-accounts/hooks";

const PAGE_TITLE_BAR_HEIGHT = "55";

export enum PrimaryPageLayoutWidth {
  FULL_WIDTH = "100%",
  DEFAULT = "1528px",
  MEDIUM = "1296px",
  NARROW = "840px",
}

export enum PrimaryPageLayoutType {
  FIXED = "fixed",
  FULL = "full",
}

export interface PrimaryPageLayoutProps {
  bg?: ColorProp;
  highlightedMenuLink?: Route;
  children?: React.ReactNode;
  maxWidth?: PrimaryPageLayoutWidth;
  breadcrumbs?: React.ReactNode;
  showExtendDiscount?: boolean;
  layoutType?: PrimaryPageLayoutType;
}

export type PageTitleBarProps = Pick<BoxProps, "bg" | "elevation"> & {
  highlightedMenuLink?: Route;
  breadcrumbs?: React.ReactNode;
  showExtendDiscount?: boolean;
};

const leftAlignedContainerStyle: CSSRulesFunction = () => ({
  marginLeft: 0,
});

export const PageTitleBarWithControls: React.FC<PageTitleBarProps> = ({
  bg,
  elevation,
  highlightedMenuLink,
  breadcrumbs,
  showExtendDiscount = false,
}) => {
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
  const [openExtendDiscountDialog, toggleExtendDiscountDialog] =
    useState(false);
  const [openNotifications, setOpenNotifications] = useState<boolean>(false);
  const { hasMultiAccountFeature } = useMultiAccountFeature();

  const { theme } = useTheme();

  const isEligibleForReferralScheme = useIsEligibleForReferral();

  const { isVariationOn: isChooseReferralRewardEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.SPARK_CHOOSE_REFERRAL_REWARD_V1,
    });

  const isBelowXlBreakpoint = useIsBreakpoint({ max: Breakpoint.Lg });
  const isBelowSBreakpoint = useIsBreakpoint({ max: Breakpoint.Sm });

  const { isVariationOn: isNotificationsEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.ASAP_PAYMENTS_NOTIFICATIONS_V1,
  });

  return (
    <>
      <Visibility visible={["block", null, null, "none"]}>
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.SpaceBetween}
          elevation={elevation}
          bg={bg}
          height={`${PAGE_TITLE_BAR_HEIGHT}px`}
          gutterV={1}
          width="100%"
        >
          <Visibility
            // @ts-expect-error - "flex" is not included in VisibilityValue interface, will raise with UXI
            visible={[isSearchOpen ? "none" : "flex", "flex", null, "none"]}
          >
            {hasMultiAccountFeature ? (
              <OrganisationSwitcher />
            ) : (
              <LinkBuilder route={Route.Home}>
                {(result) => (
                  <PlainLink {...result} layout={ButtonLayout.Inline}>
                    <GoCardlessLogo variant="dark" size="sm" />
                  </PlainLink>
                )}
              </LinkBuilder>
            )}
          </Visibility>
          <Box
            layout="flex"
            width="100%"
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.FlexEnd}
          >
            {isEligibleForReferralScheme && isChooseReferralRewardEnabled ? (
              <ChooseReferralRewardReferButton />
            ) : null}
            <SearchFormContainer
              isMobile={true}
              isOpen={isSearchOpen}
              setOpen={setSearchOpen}
            />
            {isNotificationsEnabled && (
              <PlainButton onClick={() => setOpenNotifications(true)}>
                <Box layout="flex" alignItems={AlignItems.Center}>
                  <Image
                    src={NotificationBell}
                    alt="Notifications"
                    width={12}
                    height={12}
                  />
                </Box>
              </PlainButton>
            )}

            {isBelowXlBreakpoint && (
              <Box layout="flex">
                <SiteSettingsDialog />
                <MobilePrimaryNavigation
                  highlightedMenuLink={highlightedMenuLink}
                />
              </Box>
            )}
          </Box>
        </Box>
        {showExtendDiscount && (
          <ExtendDiscountBanner
            onClickExtend={() => toggleExtendDiscountDialog(true)}
          />
        )}
      </Visibility>
      <Visibility visible={["none", null, null, "block"]}>
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={
            breadcrumbs ? JustifyContent.SpaceBetween : JustifyContent.FlexEnd
          }
          elevation={elevation}
          bg={bg}
          height={`${PAGE_TITLE_BAR_HEIGHT}px`}
          gutterV={1}
        >
          {breadcrumbs ? (
            <nav aria-label="Breadcrumbs">{breadcrumbs}</nav>
          ) : null}
          <Container
            maxContentWidth="100%"
            contentGutters={0}
            css={leftAlignedContainerStyle(theme)}
          >
            <Grid gutterH={0}>
              <Column size={12}>
                <Box
                  layout="flex"
                  alignItems={AlignItems.Center}
                  justifyContent={JustifyContent.FlexEnd}
                >
                  <Interpose node={<Space h={2} layout="inline" />}>
                    {isEligibleForReferralScheme ? (
                      isChooseReferralRewardEnabled ? (
                        <ChooseReferralRewardReferButton />
                      ) : (
                        <ReferButton />
                      )
                    ) : null}
                    <SearchFormContainer
                      isOpen={isSearchOpen}
                      setOpen={setSearchOpen}
                    />
                    {isNotificationsEnabled && (
                      <PlainButton onClick={() => setOpenNotifications(true)}>
                        <Box layout="flex" alignItems={AlignItems.Center}>
                          <Image
                            src={NotificationBell}
                            alt="Notifications"
                            width={12}
                            height={12}
                          />
                          <Space h={0.5} />
                          <Text
                            weight={FontWeight.SemiBold}
                            textWrap={TextWrap.NoWrap}
                          >
                            Notifications
                          </Text>
                        </Box>
                      </PlainButton>
                    )}

                    <SiteSettingsDropdown />
                  </Interpose>
                  {showExtendDiscount && (
                    <ExtendDiscountButton
                      onClickExtend={() => toggleExtendDiscountDialog(true)}
                    />
                  )}
                </Box>
              </Column>
            </Grid>
          </Container>
        </Box>
        <Separator spacing={[[0, 0, 0.5, 0]]} />
      </Visibility>
      {showExtendDiscount && (
        <ExtendDiscountDialog
          open={openExtendDiscountDialog}
          toggleDialog={() =>
            toggleExtendDiscountDialog(!openExtendDiscountDialog)
          }
        />
      )}
      {isBelowSBreakpoint ? (
        <NotificationsFullScreenDialog
          open={openNotifications}
          onClose={() => setOpenNotifications(false)}
        />
      ) : (
        <NotificationsSideBar
          open={openNotifications}
          onClose={() => setOpenNotifications(false)}
        />
      )}
    </>
  );
};

export const PrimaryPageLayout: React.FC<PrimaryPageLayoutProps> = ({
  breadcrumbs,
  children,
  highlightedMenuLink,
  maxWidth,
  showExtendDiscount = false,
  layoutType = PrimaryPageLayoutType.FULL,
}) => {
  const { bannersHeight, setShowLayoutBanners } = useContext(GlobalState);

  useEffect(() => {
    setShowLayoutBanners(true);
    return () => setShowLayoutBanners(false);
  }, [setShowLayoutBanners]);

  const Layout =
    layoutType === PrimaryPageLayoutType.FULL
      ? SingleFullLayoutWithNav
      : SingleFixedLayoutWithNav;

  return (
    <Layout
      navigation={
        <Sidebar
          highlightedMenuLink={highlightedMenuLink}
          height={`calc(100% - ${bannersHeight}px)`}
        />
      }
      spaceAbove={bannersHeight}
      header={
        <PageTitleBarWithControls
          bg={ColorPreset.BackgroundLight_02}
          highlightedMenuLink={highlightedMenuLink}
          breadcrumbs={breadcrumbs}
          showExtendDiscount={showExtendDiscount}
        />
      }
      maxContentWidth={maxWidth || PrimaryPageLayoutWidth.DEFAULT}
    >
      <Grid gutterH={0} css={{ height: "auto" }}>
        <Column size={12}>
          {breadcrumbs ? (
            <Visibility visible={["block", null, null, "none"]}>
              <Space v={[1.5, 1.5, 3]} />
              <nav aria-label="Breadcrumbs">{breadcrumbs}</nav>
            </Visibility>
          ) : null}
          {children}
        </Column>
      </Grid>
    </Layout>
  );
};

export default PrimaryPageLayout;
