import {
  Box,
  ColorPreset,
  Glyph,
  Interpose,
  Space,
} from "@gocardless/flux-react";
import { FC } from "react";
import { Trans } from "@lingui/macro";

import { Route, routerPush } from "src/common/routing";
import CustomerCard from "src/components/routes/SetupPayments/home/CreatePayment/CustomerCard";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

const FrequentActions: FC = () => {
  const { isVariationOn: isEasyPaymentOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_EASY_PAYMENT_DECISION,
  });
  const createPaymentsLink = isEasyPaymentOn
    ? Route.CreationTemplates
    : Route.SetupPayments;
  return (
    <Box
      gutterH={[1.5, null, 2]}
      gutterV={[1.5, null, 2]}
      bg={ColorPreset.BackgroundLight_01}
      borderRadius={1}
      borderColor={ColorPreset.BorderOnLight_04}
      borderWidth={1}
    >
      <Interpose node={<Space v={1} />}>
        <CustomerCard
          title={
            <Trans id="home.frequent-actions.create-payments">
              Create payments
            </Trans>
          }
          icon={Glyph.Money}
          onClick={() => routerPush({ route: createPaymentsLink })}
        />
        <CustomerCard
          title={
            <Trans id="home.frequent-actions.view-payments">
              View payments
            </Trans>
          }
          icon={Glyph.Table}
          onClick={() => routerPush({ route: Route.Payments })}
        />
      </Interpose>
    </Box>
  );
};

export default FrequentActions;
