import {
  Box,
  TypePreset,
  XYGrid,
  Glyph,
  FontWeight,
  ColorPreset,
  Text,
  Interpose,
  Space,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import CustomerCard from "../CreatePayment/CustomerCard";

import Illustration30DaysFree from "src/assets/png/illustration-30daysfree@2x.png";
import { Route, routerPush } from "src/common/routing";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

const GetMoreCard: React.FC = () => {
  const { isVariationOn: isEasyPaymentOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_EASY_PAYMENT_DECISION,
  });
  const createPaymentLink = isEasyPaymentOn
    ? Route.CreationTemplates
    : Route.SetupPayments;
  return (
    <Box
      spaceAbove={0.75}
      gutterV={[1, null, null, 3]}
      gutterH={[1, null, null, 3]}
      bg={ColorPreset.BackgroundLight_01}
      borderColor={ColorPreset.BorderOnLight_04}
      borderWidth={1}
      borderRadius={1.5}
    >
      <XYGrid
        templateColumns={["1fr", null, "auto 1fr"]}
        columnGap={4}
        rowGap={2}
      >
        <Box width={["100%", null, "250px"]}>
          <Box width="120px">
            <img
              src={Illustration30DaysFree}
              alt=""
              css={{ width: "100%", height: "100%" }}
            />
          </Box>
          <Box spaceAbove={2}>
            <Text preset={TypePreset.Heading_04}>
              <Trans id="home.get-more-out.title">
                Get even more out of GoCardless
              </Trans>
            </Text>
          </Box>
          <Box spaceAbove={1}>
            <Text preset={TypePreset.Body_02} weight={FontWeight.Normal}>
              <Trans id="home.get-more-out.description">
                Here are some useful links to help you save even more time with
                GoCardless.
              </Trans>
            </Text>
          </Box>
        </Box>
        <Box>
          <Interpose node={<Space v={1} />}>
            <CustomerCard
              title={
                <Trans id="home.get-more-out.create-more-payment">
                  Create more payments
                </Trans>
              }
              icon={Glyph.Money}
              onClick={() => routerPush({ route: createPaymentLink })}
            />

            <CustomerCard
              title={
                <Trans id="home.get-more-out.success-plus">
                  Enable Success + intelligent retries
                </Trans>
              }
              icon={Glyph.Refund}
              onClick={() => routerPush({ route: Route.SuccessPlusReporting })}
            />
            <CustomerCard
              title={
                <Trans id="home.get-more-out.accept-payments-on-your-website">
                  Accept payments on your website
                </Trans>
              }
              icon={Glyph.Mobile}
              onClick={() =>
                window.location.assign(
                  "https://hub.gocardless.com/s/article/Adding-a-Pay-with-GoCardless-button-to-your-website"
                )
              }
            />
          </Interpose>
        </Box>
      </XYGrid>
    </Box>
  );
};

export default GetMoreCard;
