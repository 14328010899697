import {
  Box,
  Layer,
  useTheme,
  ProgressBar,
  ColorScheme,
  ProgressBarColor,
  ProgressBarVariant,
  Text,
  XYGrid,
} from "@gocardless/flux-react";

import { headerBorderStyle } from "./styles";

export interface HeaderLayoutProps {
  progressBar?: {
    stepNumber?: number;
    totalSteps?: number;
  };
  className?: string;
  children?: React.ReactNode;
  /**
   * @default true
   */
  showBottomBorder?: boolean;
}

const HEADER_HEIGHT_MD_LG = "72px";
const HEADER_HEIGHT_SM = "64px";
const HEADER_HEIGHT_XS = "56px";

/**
 * Provides the shell for the header, including height, paddings and progress bar
 */
export const HeaderLayout: React.FC<HeaderLayoutProps> = ({
  children,
  className,
  progressBar,
  showBottomBorder = true,
}) => {
  const { theme } = useTheme();
  const showProgressBar = Boolean(
    progressBar?.stepNumber && progressBar?.totalSteps
  );

  return (
    <Box
      className={className}
      css={
        showProgressBar || !showBottomBorder
          ? undefined
          : headerBorderStyle(theme)
      }
    >
      <Layer mode="relative">
        <Box
          gutterH={[1.5, null, 2, null]}
          height={[HEADER_HEIGHT_XS, HEADER_HEIGHT_SM, HEADER_HEIGHT_MD_LG]}
        >
          <XYGrid height="100%">{children}</XYGrid>
        </Box>
      </Layer>
      {showProgressBar && (
        <ProgressBar
          variant={ProgressBarVariant.Solid}
          colorScheme={ColorScheme.OnLight}
          color={ProgressBarColor.Info}
          value={progressBar?.stepNumber || 0}
          max={progressBar?.totalSteps || 0}
          label={<Text id="progress-bar">progress bar</Text>}
          aria-labelledby="progress-bar"
          labelVisibility="hidden"
        />
      )}
    </Box>
  );
};
